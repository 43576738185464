import IMG from './rabbit.png';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Showcase</li>
          <li>Contact</li>
        </ul>
      </header>

      <section>
        <div>
        <h1>About</h1>
        <p><strong>Yunchi LIN</strong>, a highly skilled <span className='dotted'>front-end developer</span> from Taiwan, has been living in Paris since 2015. With 8 years of experience in the industry. She specializes in developing user-friendly and responsive web interfaces utilizing technologies such as HTML, CSS, JavaScript, and React. With a passion for creating visually appealing and functional websites, Yunchi consistently stays up-to-date with the latest development trends and technologies. Known for being a problem-solver, and constant learner, she brings a wealth of skills and expertise to any project she works on. Excited to bring their abilities to new challenges, she is eager to be a valuable asset to any team.</p>
        
        </div>
        <div>
          <img src={IMG} alt="rabbit" width="500" />
        </div>

        </section>

      <section>
        
      </section>
    </div>
  );
}

export default App;
